import { startCase, toArray } from "lodash";
import React from "react";
import { historyParamHash } from "shared/useHistoryParam";
import { stringifyUrl } from "query-string";
import { types } from "api";
import { HStack } from "libs/layouts";
import { getLocationLabelPlural } from "modules/graph/services";
import { types_LocationEnum } from "api/gen";
import { remapCategories } from "modules/dataflow-core/location-categories-remap";
import { LocationIcon } from "./molecules/LocationIcon";

const locationsMessages = {
  all: "All",
  mail: "Email",
  mailDomain: "Email domain",
  mailAccount: "Address",
  cloudStorage: "Cloud Storage",
  cloudProvider: "Provider",
  cloudStoragePath: "Path",
  cloudApps: "Cloud App",
  cloudApp: "App",
  cloudAppsAccountName: "Account name",
  website: "Website",
  websiteDomain: "Domain",
  websiteUrl: "URL",
  websiteCategory: "Website category",
  share: "Shared Folder",
  shareHostname: "Hostname",
  shareGroupName: "Group name",
  sharePath: "Path",
  endpoint: "Endpoint",
  endpointHostname: "Hostname",
  endpointGroupName: "Group name",
  endpointLocalUserName: "Local user name",
  endpointPath: "Path",
  endpointApps: "Endpoint App",
  endpointAppsCommandline: "Command line",
  endpointAppsGroupName: "Group name",
  endpointAppsAppName: "Application name",
  endpointAppsHostname: "Hostname",
  endpointAppsLocalUserName: "Local user name",
  endpointAppsPath: "Path",
  removableMedia: "Removable media",
  mediaCategory: "Category",
  removableMediaLocalUserName: "Local user name",
  removableMediaDeviceName: "Device name",
  removableMediaSerialNumber: "Serial number",
  removableMediaPath: "Path",
  printer: "Printer",
  printerName: "Printer name",
};

export const zones = {
  internal: {
    label: "Internal",
    value: "Internal",
    color: "#E0EEFD",
  },
  external: {
    label: "External",
    value: "External",
    color: "rgba(232, 99, 102, 0.2)",
  },
  undefined: {
    label: "Undefined",
    value: "Undefined",
    color: "#B4B4B4",
  },
};

export const mediaCategories = {
  usb: {
    label: "USB Devices",
    value: "usb",
  },
  optical: {
    label: "CD/DVD",
    value: "optical",
  },
};
// TODO remove unused
export const locations = {
  all: {
    label: locationsMessages.all,
    value: "*",
    color: "#ccc",
    keywords: [
      {
        label:
          "Email account or Email subject or Hostname or Path or Removable device name or Salesforce account name or Url",
      },
    ],
  },
  mail: {
    label: locationsMessages.mail,
    value: "mail",
    color: "#4CCBAB",
    clusterColor: "#4CCBAB33",
    keywords: ["email_account", { label: "Email subject" }],
    source: true,
    destination: true,
    fields: {
      domain: {
        name: "mail.domain",
        suggestName: "domain",
        label: locationsMessages.mailDomain,
      },
      email_account: {
        suggestName: "email_account",
        name: "mail.email_account",
        label: locationsMessages.mailAccount,
      },
    },
  },
  cloud_storage: {
    label: locationsMessages.cloudStorage,
    value: "cloud_storage",
    color: "#3E75C8",
    clusterColor: "#3E75C833",
    keywords: ["path"],
    source: true,
    destination: true,
    fields: {
      cloud_provider: {
        name: "cloud_storage.cloud_provider",
        suggestName: "cloud_provider",
        label: locationsMessages.cloudProvider,
      },
      path: {
        name: "cloud_storage.path",
        suggestName: "path",
        label: locationsMessages.cloudStoragePath,
      },
    },
  },
  cloud_apps: {
    label: locationsMessages.cloudApps,
    value: "cloud_apps",
    color: "#3E75C8",
    clusterColor: "#3E75C833",
    keywords: ["salesforce_account_name"],
    source: true,
    destination: true,
    fields: {
      cloud_app: {
        name: "cloud_apps.cloud_app",
        label: locationsMessages.cloudApp,
      },
      salesforce_account_name: {
        name: "cloud_apps.salesforce_account_name",
        label: locationsMessages.cloudAppsAccountName,
      },
    },
  },
  website: {
    label: locationsMessages.website,
    value: "website",
    color: "#8B60AD",
    clusterColor: "#8B60AD33",
    keywords: ["url"],
    source: true,
    destination: true,
    fields: {
      domain: {
        name: "website.domain",
        label: locationsMessages.websiteDomain,
      },
      url: {
        name: "website.url",
        label: locationsMessages.websiteUrl,
      },
      category: {
        name: "website.category",
        label: locationsMessages.websiteCategory,
      },
    },
  },
  share: {
    label: locationsMessages.share,
    value: "share",
    color: "#E56574",
    clusterColor: "#E5657433",
    keywords: ["path", "hostname"],
    source: true,
    destination: true,
    fields: {
      hostname: {
        name: "share.hostname",
        label: locationsMessages.shareHostname,
      },
      group_name: {
        name: "share.group_name",
        label: locationsMessages.shareGroupName,
      },
      path: {
        name: "share.path",
        label: locationsMessages.sharePath,
      },
    },
  },
  endpoint: {
    label: locationsMessages.endpoint,
    value: "endpoint",
    color: "#FE9E46",
    clusterColor: "#FE9E4633",
    keywords: ["path", "hostname"],
    source: true,
    destination: true,
    fields: {
      hostname: {
        name: "endpoint.hostname",
        label: locationsMessages.endpointHostname,
      },
      group_name: {
        name: "endpoint.group_name",
        label: locationsMessages.endpointGroupName,
      },
      local_user_name: {
        name: "endpoint.local_user_name",
        label: locationsMessages.endpointLocalUserName,
      },
      path: {
        name: "endpoint.path",
        label: locationsMessages.endpointPath,
      },
    },
  },
  endpoint_apps: {
    label: locationsMessages.endpointApps,
    value: "endpoint_apps",
    color: "#FE9E46",
    clusterColor: "#FE9E4633",
    keywords: ["path", "hostname"],
    source: true,
    destination: true,
    fields: {
      app_commandline: {
        name: "endpoint_apps.app_commandline",
        label: locationsMessages.endpointAppsCommandline,
      },
      group_name: {
        name: "endpoint_apps.group_name",
        label: locationsMessages.endpointAppsGroupName,
      },
      app_name: {
        name: "endpoint_apps.app_name",
        label: locationsMessages.endpointAppsAppName,
      },
      hostname: {
        name: "endpoint_apps.hostname",
        label: locationsMessages.endpointAppsHostname,
      },
      local_user_name: {
        name: "endpoint_apps.local_user_name",
        label: locationsMessages.endpointAppsLocalUserName,
      },
      path: {
        name: "endpoint_apps.path",
        label: locationsMessages.endpointAppsPath,
      },
    },
  },
  removable_media: {
    label: locationsMessages.removableMedia,
    value: "removable_media",
    color: "#2ED6D9",
    clusterColor: "#2ED6D933",
    keywords: ["path", "deviceName"],
    source: true,
    destination: true,
    fields: {
      local_user_name: {
        name: "removable_media.local_user_name",
        label: locationsMessages.removableMediaLocalUserName,
      },
      deviceName: {
        name: "removable_media.removable_device_name",
        label: locationsMessages.removableMediaDeviceName,
      },
      deviceSerial: {
        name: "removable_media.removable_device_id",
        label: locationsMessages.removableMediaSerialNumber,
      },
      mediaCategory: {
        name: "removable_media.media_category",
        label: locationsMessages.mediaCategory,
      },
      path: {
        name: "removable_media.path",
        label: locationsMessages.removableMediaPath,
      },
    },
  },
  printer: {
    label: locationsMessages.printer,
    value: "printer",
    color: "#ABDA7C",
    clusterColor: "#ABDA7C33",
    keywords: ["printerName"],
    source: false,
    destination: true,
    fields: {
      printerName: {
        name: "printer.printer_name",
        label: locationsMessages.printerName,
      },
    },
  },
};

export const LOCATION_OPTIONS = toArray(locations);
export function getLocationLabel(key: keyof typeof locations) {
  return locations[key]?.label;
}
export function mapLocationsLabel(loc: types.ExpandableLocationItem) {
  return (
    <HStack style={{ whiteSpace: "nowrap" }} space={1} as="span" align="center">
      <LocationIcon item={loc} shouldShowGenericIconForNested={true} />
      <span>{formatLocation(loc)}</span>
    </HStack>
  );
}

export function formatLocation(loc: types.ExpandableLocationItem) {
  if (loc.expand_path?.length === 1) {
    return getLocationLabel(loc.location as any);
  }
  if (loc.location === "cloud_storage" && loc.expand_path?.length === 2) {
    return serializeSearch(loc);
  }
  return [serializeSearch(loc), remapCategories(loc.location_outline)].filter(Boolean).join(" ");
}

function serializeSearch(loc: types.ExpandableLocationItem) {
  if (loc.location === "cloud_storage") {
    return startCase(loc.expand_path![1]);
  }
  if (loc?.expand_path?.length === 2) {
    return "";
  }
  const query = loc.query.rules?.[0].conditions?.find((el) => el.field_name !== "location");
  if (!query) {
    return "";
  }
  switch (loc.location) {
    case "removable_media":
      return query.values?.[0].value;
    case "endpoint":
      return query.values?.[0].value;
    case "endpoint_apps":
      return query.values?.[0].value;
  }
  return "";
}

export function getLocationsWidgetItemLabel(
  { location_item }: types.ExpandableLocation,
  depth: number
) {
  const name = location_item.path_component;

  if (depth === 0) {
    return getLocationLabelPlural(name) ?? name;
  } else if (depth === 1 && location_item.location === types_LocationEnum.LocationCloudStorage) {
    return startCase(name);
  }

  return name;
}

export function getLocationItemId(location: {
  expand_path?: string[] | null;
  location_path?: string[] | null;
}) {
  const path = location.expand_path || location.location_path || [];

  return path.join(",");
}

export const createTraceDetailsLink = (origin: string, row: any) => {
  const url = new URL(
    location.origin +
      "/trace-details/" +
      historyParamHash(
        JSON.stringify({
          flow_id: row?.id,
          origin,
          row,
          originURL: location.href,
        })
      )
  );
  return url.toString();
};

// todo handle case where link size is too big and we need to generate hash instead
export const createIncidentShareLink = (
  origin: string,
  id: string | string[],
  pathOnly: boolean = false
) => {
  return stringifyUrl({
    url: (pathOnly ? "" : location.origin) + "/incidents",
    query: {
      id: id,
      origin: origin,
    },
  });
};
