import { useCallback, useMemo } from "react";
import type { QueryKey } from "@tanstack/query-core";
import { keepPreviousData, useInfiniteQuery } from "@tanstack/react-query";

interface FetchResult<T> {
  result: T[];
  nextPage?: number | string;
  total?: number;
}
type FetchData<T> = (page: number) => Promise<FetchResult<T>>;
export interface GridCollectionOptions<T> {
  key: QueryKey;
  fetchData: FetchData<T>;
  enabled?: boolean;
  staleTime?: number;
  initialPageParam?: any;
}

export function useGridCollection<T extends any>({
  key,
  fetchData,
  enabled = true,
  staleTime,
  initialPageParam = 0,
}: GridCollectionOptions<T>) {
  const query = useInfiniteQuery<FetchResult<T>>({
    queryKey: key,
    queryFn: ({ pageParam: nextPage = 0 }) => fetchData(nextPage as number),
    getNextPageParam: (lastPage) => lastPage.nextPage || undefined,
    placeholderData: keepPreviousData,
    initialPageParam,
    enabled,
    staleTime,
  });

  const total = query.data?.pages[0]?.total;
  const data = useMemo(() => {
    if (!query.data) {
      return null;
    }

    return query.data.pages.flatMap((page) => page.result);
  }, [query.data]);

  const onGetMoreRows = useCallback(async () => {
    const { data } = await query.fetchNextPage();

    if (data?.pages) {
      const newRows = data.pages[data.pages.length - 1].result;
      return { newRows };
    }

    return { newRows: null };
  }, [query]);

  return { ...query, onGetMoreRows, data, total };
}
